import { faGithub, faLinkedin } from '@fortawesome/free-brands-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { graphql } from 'gatsby';
import Img from 'gatsby-image';
import React from 'react';

import BlogPostPreview from '../components/BlogPostPreview';
import Layout from '../components/Layout';
import SEO from '../components/SEO';

const icons = {
  linkedin: faLinkedin,
  github: faGithub,
};

const Team = ({ data: { markdownRemark } }) => {
  const {
    html,
    excerpt,
    recentPosts,
    frontmatter: {
      path,
      name,
      jobTitle,
      image,
      seoImage,
      profileUrl,
      profileType,
      email,
    },
  } = markdownRemark;

  function renderBlogPosts() {
    return (
      <>
        <h2 className="mb-4">Recent posts</h2>

        <div className="flex flex-row flex-wrap justify-around">
          {recentPosts.map((post) => (
            <BlogPostPreview key={post.path} node={post} />
          ))}
        </div>
      </>
    );
  }

  return (
    <Layout bodyClass="standard-layout">
      <SEO
        title={`${name}'s profile`}
        description={excerpt}
        image={{
          src: seoImage.childImageSharp.fixed.src,
          width: seoImage.childImageSharp.fixed.width,
          height: seoImage.childImageSharp.fixed.height,
        }}
        url={path}
      />

      <section className="header-section bg-black">
        <div className="container">
          <h1 className="title">{name}</h1>
          <p>{jobTitle}</p>
        </div>
      </section>

      <div className="container">
        <section className="body-section">
          <div className="flex flex-col md:flex-row items-center mb-8">
            <div className="flex flex-col items-center md:w-1/2 lg:w-1/3">
              <Img
                className="rounded-full mb-4"
                alt={name}
                fixed={image.childImageSharp.fixed}
              />
              <p>
                Follow me on{' '}
                <a
                  className="hover:underline"
                  target="_blank"
                  rel="noopener noreferrer"
                  href={profileUrl}
                >
                  <FontAwesomeIcon className="mr-1" icon={icons[profileType]} />
                </a>
              </p>
              <p>
                <a href={`mailto:${email}`}>{email}</a>
              </p>
            </div>

            <div className="md:ml-8 md:w-1/2 lg:w-2/3">
              <h2 className="w-full">Bio</h2>
              <div
                className="markdown-body"
                dangerouslySetInnerHTML={{ __html: html }}
              />
            </div>
          </div>

          {!!(recentPosts && recentPosts.length) && renderBlogPosts()}
        </section>
      </div>
    </Layout>
  );
};

export const query = graphql`
  query($id: String!) {
    markdownRemark(id: { eq: $id }) {
      html
      excerpt(pruneLength: 160)
      frontmatter {
        path
        name
        jobTitle
        profileUrl
        profileType
        email
        image {
          childImageSharp {
            fixed(width: 200, quality: 100) {
              ...GatsbyImageSharpFixed
            }
          }
        }
        seoImage: image {
          childImageSharp {
            fixed(width: 1200, height: 628, quality: 100, fit: CONTAIN) {
              ...GatsbyImageSharpFixed
            }
          }
        }
      }
      recentPosts {
        id
        excerpt
        timeToRead
        title
        path
        publishedDate
        image {
          childImageSharp {
            fluid(maxWidth: 500) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }
`;

export default Team;
